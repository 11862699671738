<template>
  <div class="menu-body">
    <div class="div-menuList">
      <div v-for="(item, index) in props.fristTitle " :key="index" class="menu-list" @click="goNext(item, index)"
        :class="[item.path == count ? 'menu-list-cilck' : 'menu-list']">
        <a-dropdown>
          <div @click.prevent>
            {{ item.name }}
          </div>
          <template #overlay>
            <a-menu v-show="item.nodes?.length !== 0">
              <a-menu-item v-for="(e, i) in item.nodes" :key="i" @click="goNext(item, index, i)" class="a-menu-item"><div class="dashfcj">{{ e.name }}</div></a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, defineProps, defineExpose, watch, onMounted } from 'vue'
import { useRouter, useRoute } from "vue-router"

onMounted(() => {
	if (isMobile()) {
		isPc.value = false
	} else {
		isPc.value = true
	}
})
const router = useRouter()
const route = useRoute()
const props = defineProps({
  fristTitle: {
    type: Array,
    default: () => []
  }
})
// 跳转页面
const isPc = ref(true)
const goNext = (e, index, i) => {
	console.log(i)
	if (i === undefined) {
		i = 0
	}
	if (e.name === '会员之窗') {
		if (i === 1) {
			router.push('/vipHome/applyFor')
		} else if (i === 0) {
			router.push('/vipHome/vipList')
		}  else if (i === 4) {
			router.push('/vipHome/advise')
		}  else if (i === 3) {
			router.push('/vipHome/vipMien')
		}  else if (i === 2) {
			router.push('/vipHome/certificateDownload')
		} else if (i === 5) {
			window.open('https://jigaixiehui.baijiayunxiao.com/course')
		}
		count.value = sessionStorage.getItem('vipApplyFor')
	} else if (e.name === '投资融资' && i === 2) {
		if (isPc.value) {
			window.open('https://www.jssjrfw.com')
		} else {
			window.open('https://app.jssjrfw.com/a/17vRz9')
		}
	} else {
		sessionStorage.setItem('menuCount', index)
		sessionStorage.setItem('menuValue', JSON.stringify(e))
		router.push({path: `${e.path}`, query: { id: `${e.id}`, code: `${i}`}, isText: 'false'})
	}
}

const isMobile = () =>  {
	let flag = navigator.userAgent.match(
			/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	)
	return flag
}

const count = ref(route.path)

const changeCount = (e) => {
  count.value = e
}
watch(() => route.path,
		() => {
			count.value = route.path
		})
defineExpose({ changeCount })
</script>

<style scoped>
.div-menuList {
  display: flex;
  justify-content: center;
  color: white;
	caret-color: transparent;
}

.menu-list {
  font-size: 14px;
  height: 60px;
  width: 110px;
  line-height: 60px;
}

.menu-list:hover {
  height: 60px;
  width: 110px;
  font-weight: bold;
  border-bottom: 4px solid white;
  color: white;
  cursor: pointer;
}

.menu-list-cilck {
  height: 60px;
  width: 110px;
  border-bottom: 4px solid white;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.menu-body {
  z-index: 1;
  width: 100%;
  background-color: #13549C;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep  .a-menu-item:hover {
	background-color: #13549c !important;
	color: #FFFFFF;
}
</style>
