import { createStore } from 'vuex'

export default createStore({
  state: {
	treeData: []
  },
  getters: {

  },
  mutations: {
	getTreeData: (state, value) => {
		state.getTreeData = value
	},
  },
  actions: {
  },
  modules: {
  }
})
