import axios from "@/utils/requst.js";

// 分类及其数据，首页用
export function getTreeCategoryList (data) {
	return axios({
    url: '/cms-api/category/getTreeCategoryList',
    method: 'post',
    data,
  })
}

// 相关链接
export function getWebNewLinkList (data){
	return axios({
    url: '/cms-api/link/getWebNewLinkList',
    method: 'post',
    data,
  })
}

//首页轮播图
export function getCarouselList (data){
	return axios({
    url: '/cms-api/homePicture/list',
    method: 'post',
    data,
  })
}

// 分类数据获取
export function getArticleIPageListByCategory (params){
	return axios({
    url: '/cms-api/category/getArticleIPageListByCategory',
    method: 'get',
    params,
  })
}

// 获取文章信息
export function getArticleDetailByCategoryId (params){
	return axios({
    url: '/cms-api/category/getArticleDetailByCategoryId',
    method: 'get',
    params,
  })
}

// 建议提交
export function submitAdvice (data){
	return axios({
		url: '/questionary/text/submitAdvice',
		method: 'post',
		data
	})
}

// 投资融资
export function submitInvestFinance (data){
	return axios({
		url: '/questionary/text/submitInvestFinance',
		method: 'post',
		data
	})
}

// 会员名单
export function getIPageTableMemberList(data) {
	return axios({
		url: '/questionary/table/getIPageTableMemberList',
		method: 'post',
		data,
	})
}

// 轮播图首页中下
export function getHomeBannerList (params){
	return axios({
		url: '/cms-api/article/getHomeBannerList',
		method: 'get',
		params,
	})
}

// 查新闻详情
export function getArticleDetail (params){
	return axios({
		url: '/cms-api/article/getArticleDetail',
		method: 'get',
		params,
	})
}
// 首页搜索文章
export function getArticleSearch (params){
	return axios({
		url: '/cms-api/article/search',
		method: 'get',
		params,
	})
}
