import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/aboatAssociation',
    name: 'aboatAssociation',
    component: () => import('../views/aboatAssociation/index.vue'),
    children: [{
      path: '/aboatAssociation/introduction',
      name: 'introduction',
      component: () => import('../views/aboatAssociation/introduction.vue')
    },
    ]
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('../views/notice/index.vue')
  },
  {
    path: '/policyInformation',
    name: 'policyInformation',
    component: () => import('../views/policyInformation/index.vue')
  },
  {
    path: '/workDynamic',
    name: 'workDynamic',
    component: () => import('../views/workDynamic/index.vue')
  },
  {
    path: '/policyInformation',
    name: 'policyInformation',
    component: () => import('../views/policyInformation/index.vue')
  }, {
    path: '/three',
    name: 'three',
    component: () => import('../views/threeLibrary/index.vue')
  }, {
    path: '/technology',
    name: 'technology',
    component: () => import('../views/technology/index.vue')
  }, {
    path: '/industryReport',
    name: 'industryReport',
    component: () => import('../views/industryReport/index.vue')
  },
  {
    path: '/investment',
    name: 'investment',
    component: () => import('../views/investment/index.vue')
  },
  {
    path: '/partyWork',
    name: 'partyWork',
    component: () => import('../views/partyWork/index.vue')
  },
  {
    path: '/vipHome',
    name: 'vipHome',
    component: () => import('../views/vipHome/index.vue'),
    children: [{
      path: '/vipHome/applyFor',
      name: 'applyFor',
      component: () => import('../views/vipHome/applyFor.vue'),
    },
    {
      path: '/vipHome/vipList',
      name: 'vipList',
      component: () => import('../views/vipHome/vipList.vue'),
    },
	{
		path: '/vipHome/vipMien',
		name: 'vipMien',
		component: () => import('../views/vipHome/vipMien.vue'),
	},
	{
		path: '/vipHome/advise',
		name: 'advise',
		component: () => import('../views/vipHome/advise.vue'),
	},
	{
		path: '/vipHome/certificateDownload',
		name: 'certificateDownload',
		component: () => import('../views/vipHome/certificateDownload.vue'),
	},
  ]
  },
	{
	path: '/newsPage',
	name: 'newsPage',
	component: () => import('../views/newsPage/index.vue'),
	}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
