import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

import axios from 'axios'
router.afterEach((to, from, next) => {
	console.log(to, from, next)
	window.scrollTo(0, 0);
})
const app = createApp(App)
app.use(Antd)
app.use(store)
app.use(router)
app.mount('#app')
app.config.globalProperties.$http = axios
