<template>
  <a-config-provider :locale="locale">
    <HomeView></HomeView>
  </a-config-provider>
</template>

<script setup>
import HomeView from './views/HomeView.vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { ref } from 'vue';
dayjs.locale('zh-cn');

const locale = ref(zhCN)
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0 auto;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
}

/* 修改ant全局样式 */
.ant-modal-root .ant-btn.ant-btn-primary {
  font-size: 12px;
  border: none;
  color: #fff;
  background-image: linear-gradient( 90deg, #13549C, #3683db) ;
  box-shadow: 0px 5px 10px #13539c73;
}
.ant-modal-root .ant-modal-confirm-title h3 {
  margin-bottom: 12px;
  font-weight: 600;
}
</style>
