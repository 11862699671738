<template>
  <div class="home">
    <div
      style="
        width: 100%;
        height: 320px;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
      "
    >
      <div class="logo-group">
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <img src="../assets/logo-cr.png" alt="" class="logo-cr" />
          <img src="../assets/logo-font.png" alt="" class="logo-font" />
          <div class="search-box">
            <a-select
              v-model:value="searchWord"
              show-search
              style="width: 180px"
              placeholder="请输入查询内容"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              :options="searchData"
              :field-names="{ label: 'title', value: 'id' }"
              @select="handleSelect"
              @search="handleChange"
              @blur="handleBlur"
              @popupScroll="popupScroll"
            >
            </a-select>
            <div class="icon-box">
              <search-outlined class="ant-select-suffix" />
            </div>
          </div>
        </div>
      </div>
      <div class="rectangle">
        <a-carousel autoplay :dots="false">
          <div>
            <img src="../assets/carousel_1.png" alt="" />
          </div>
          <div>
            <img src="../assets/carousel_2.png" alt="" />
          </div>
        </a-carousel>
      </div>
    </div>
    <div class="top-menu">
      <HomeMenu ref="homeMenu" :fristTitle="fristTitle"></HomeMenu>
    </div>
    <div class="content">
      <keep-alive>
        <router-view @changeCount="changeCount" :fristTitle="fristTitle" />
      </keep-alive>
    </div>
    <div class="bottom">
      <div class="center-logo">
        <div class="logo">
          <div class="logo-1">
            <img src="../assets/logo-cr.png" alt="" />
          </div>
          <div class="logo-2">
            <img src="../assets/logo-font.png" alt="" />
          </div>
        </div>
        <div class="menu">
          <div
            v-for="(item, index) in fristTitle"
            style="cursor: pointer"
            :key="index"
            @click="goNext(item, index)"
          >
            {{ item.name }}
            <div class="vertical"></div>
          </div>
        </div>
        <div class="tip">
          <div>
            版权所有：江苏省企业技术改造协会 &nbsp &nbsp &nbsp
            <span @click="goWin" style="cursor: pointer"
              >网站备案号：苏ICP备2023013980-1</span
            >
          </div>
          <div>指导单位：江苏省工业和信息化厅</div>
          <div>技术支持：一拓通信集团股份有限公司</div>
        </div>
      </div>
      <div style="display: flex; flex-direction: column">
        <div class="wechat">
          <img src="../assets/WechatIMG33.jpeg" alt="" />
        </div>
        <div class="right">扫描关注</div>
      </div>
    </div>
    <a-back-top />
    <div class="float-link" v-if="isLinkShow">
      <a href="https://gjxy.sosoq.net/index/user/login.html" target="_blank">
        <img src="../assets/school_link.jpg" alt="" />
      </a>
      <img
        src="../assets/close.png"
        class="close"
        @click="handleLinkCloseClick"
      />
    </div>
  </div>
</template>

<script setup>
import HomeMenu from './homeMenu'
import { onMounted, reactive, ref } from 'vue'
import {
  getTreeCategoryList,
  getCarouselList,
  getArticleSearch,
} from '@/api/index'
import { useRouter } from 'vue-router'
import { SearchOutlined } from '@ant-design/icons-vue'

onMounted(() => {
  getFristMenu()
  getCarousel()
})

const router = useRouter()
const fristTitle = ref([
  {
    id: 1,
    name: '首页',
    path: '/',
    nodes: [],
  },
])

// const treeCategoryList = ref([])
const getFristMenu = () => {
  getTreeCategoryList().then((res) => {
    res.forEach((e) => {
      if (e.pid === 0) {
        const obj = {
          id: e.id,
          name: e.name,
          path: e.router,
          nodes: e.nodes,
        }
        fristTitle.value.push(obj)
      }
    })
    fristTitle.value.forEach((item) => {
      if (item.name === '会员之窗') {
        item.nodes.forEach((e) => {
          if (e.name === '会员风采') {
            sessionStorage.setItem('vipMien', e.id)
          } else if (e.name === '入会申请') {
            sessionStorage.setItem('vipApplyFor', item.path)
          }
        })
      }
    })
  })
}
// 首页顶部轮播图
const arrCarousel1 = ref([])
const arrCarousel2 = ref([])
const carousel = ref([])
const getCarousel = () => {
  getCarouselList().then((res) => {
    res.forEach((e) => {
      if (e.location === 1) {
        const arr = []
        arr.push(e)
        carousel.value = arr
      } else if (e.location === 2) {
        arrCarousel1.value.push(e)
        console.log(arrCarousel1.value)
        sessionStorage.setItem(
          'firstCarousel',
          JSON.stringify(arrCarousel1.value)
        )
      } else if (e.location === 3) {
        arrCarousel2.value.push(e)
        sessionStorage.setItem(
          'secondCarousel',
          JSON.stringify(arrCarousel2.value)
        )
      }
    })
  })
}

const homeMenu = ref()
const changeCount = (e) => {
  homeMenu.value.changeCount(e)
}
const goWin = () => {
  window.open('https://beian.miit.gov.cn/')
}
// 跳转页面
const goNext = (e, index) => {
  sessionStorage.setItem('menuCount', index)
  sessionStorage.setItem('menuValue', JSON.stringify(e))
  router.push({
    path: `${e.path}`,
    query: { id: `${e.id}`, code: '0' },
    isText: 'false',
  })
}

// 首页搜索
const searchWord = ref(null)
const page = reactive({
  pageNo: 1,
  pageSize: 10,
  totalPage: 0,
})
const searchData = ref([])
const isScroll = ref(false)
const handleChange = (val) => {
  // console.log(888888888,val);
  searchWord.value = val
  if (searchWord.value == '') {
    searchWord.value = null
  }
  // 调接口获取搜索结果
  let obj = {
    title: searchWord.value,
    pageNo: isScroll.value ? page.pageNo : 1,
    pageSize: page.pageSize,
  }
  getArticleSearch(obj).then((res) => {
    if (res.status == 200) {
      if (isScroll.value) {
        searchData.value.push(...res.data.records)
        isScroll.value = false
      } else {
        searchData.value = res.data.records
      }
      page.totalPage = res.data.pages
    }
  })
}
const handleSelect = (value, option) => {
  // console.log(option);
  // 选中后跳转文章详情
  if (
    option.linkUrl === undefined ||
    option.linkUrl === '' ||
    option.linkUrl === null
  ) {
    fristTitle.value.forEach((item) => {
      // console.log(item.name === option.name);
      console.log(555, item.name, option.name)
      if (item.name === option.name) {
        console.log(666, item)
        sessionStorage.setItem('menuValue', JSON.stringify(item))
        router.push(
          {
            path: `${item.path}`,
            query: { id: option.id, code: 0, isText: 'true' },
          },
          () => {},
          () => {}
        )
        // setTimeout(()=>{
        // 	refresh()
        // },100)
      }
    })
  } else {
    window.open(option.linkUrl)
  }
}
// 失焦清空选项
const handleBlur = () => {
  searchData.value = []
}
// 下拉滚动
const popupScroll = (e) => {
  const { target } = e
  const { scrollTop, scrollHeight, clientHeight } = target

  if (scrollTop + clientHeight == scrollHeight) {
    // 已经到达底部，触发分页逻辑
    // todo 这里就可以触发加载下一页请求
    if (page.pageNo == page.totalPage) {
      return
    }
    isScroll.value = true
    page.pageNo++
    handleChange(searchWord.value)
  }
}
// const refresh = () => {
//   router.go(0)
// }

const isLinkShow = ref(true)
const handleLinkCloseClick = () => {
  isLinkShow.value = false
}
</script>

<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: rgb(248, 248, 248);
}

.top-img {
  z-index: -666;
  width: 100%;
  height: 350px;
}

.top-menu {
  width: 100%;
  background-color: #13549c;
  display: flex;
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(#386eaa 1%, #ffffff);
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  width: 100%;
  background: url('../assets/bottom.png');
  font-size: 10px;
  background-size: cover;
  .left {
    width: 300px;
    color: #ffffff;
    font-size: 5px;
    line-height: 30px;
    text-align: left;
  }

  .center-logo {
    width: 800px;
    height: 150px;
    margin: 0 60px;
    .logo {
      display: flex;
      justify-content: center;
      .logo-1 {
        img {
          height: 50px;
          width: 50px;
          background: #143f87;
          background-size: cover;
        }
      }
      .logo-2 {
        img {
          height: 50px;
          width: 220px;
          background: #143f87;
          background-size: cover;
        }
      }
    }

    .menu {
      display: flex;
      justify-content: center;
      font-size: 14px;
      margin-top: 10px;
      div {
        padding: 0 5px;
        color: #ffffff;
      }
    }

    .tip {
      margin-top: 25px;
      font-size: 12px;
      color: #ffffff;
    }
  }

  .right {
    color: #ffffff;
  }
}

.logo-group {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  .logo-cr {
    margin-top: 30px;
    height: 80px;
    width: 80px;
  }
  .logo-font {
    height: 100px;
    width: 500px;
  }
}
.rectangle {
  .ant-carousel :deep(.slick-slide) {
    overflow: hidden;
  }

  .ant-carousel :deep(.slick-slide img) {
    width: 100%;
    height: 350px;
  }
}
.wechat {
  img {
    height: 70px;
    width: 70px;
    margin-bottom: 10px;
  }
}

.search-box {
  display: flex;
  margin-top: 10px;
  .icon-box {
    width: 30px;
    // height: 20px;
    background-color: #13549c;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    .ant-select-suffix {
      color: #ffffff;
    }
  }
  :deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    opacity: 0.5;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  // :deep(.ant-select-focused:not(.ant-select-tinyColor-ledisabled) .ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  // 	border-color: #13549c!important;
  // 	box-shadow: 0 0 0 2px #13549c;
  // }
}
.float-link {
  position: fixed;
  width: 100px;
  right: 5px;
  bottom: 50px;
  border-radius: 4px;
  overflow: hidden;
  a {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .close {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
</style>
